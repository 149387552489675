@import "../colors";

.listbox-item {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  border-color: map-get($sh-colors, "black-40");
}
.listbox-item:hover {
  border-color: map-get($sh-colors, "black-80");
}

.listbox-one {
  flex-basis: 80px !important;
  padding-right: 20px !important;
  .listbox-title {
    margin-right: 60px;
  }
}

.listbox-title {
  font-family: "Siemens Sans", "SH-Bree-Text", "Open Sans", "Roboto",
    "Droid Sans", "Segoe UI", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 18px !important;
  align-items: center;
  padding: 5px 16px;
  height: 55px;
  border-width: 0 0 1px 0;
  border-color: inherit;
  border-style: solid;
  background: map-get($sh-colors, "black-5");
  font-weight: 600;
  color: map-get($sh-colors, "black-60");
}

.listbox-field-layout {
  display: flex !important;
  justify-content: center;
}

.k-listbox {
  height: 300px;
  width: 100%;
  border-color: inherit;
  .k-list-item {
    height: 50px;
    border-width: 0 0 1px 0;
    border-color: map-get($sh-colors, "black-30");
    border-style: solid;
    font-size: 16px !important;
    color: map-get($sh-colors, "black-90");
  }
}

.k-list-item.k-selected {
  color: map-get($sh-colors, "orange-dark") !important;
  background-color: map-get($sh-colors, "black-10") !important;
}

.k-list-scroller {
  border-width: 0 0 1px 0 !important;
  border-color: inherit !important;
}

.k-listbox-toolbar {
  display: flex;
  align-items: center;
  margin-left: 20px !important;
}

.listbox-field-title {
  font-size: 12px;
  color: map-get($sh-colors, "black-60");
  position: relative;
  left: 15px;
  padding: 10px 0;
}

.listbox-error {
  .k-list-scroller {
    border-color: map-get($sh-colors, "red") !important;
  }
  .listbox-title {
    border-color: map-get($sh-colors, "red");
  }
}



.k-reset {
    li {
      margin-top: 0 !important;
      display: flex;
      justify-content: center;
      button {
        border-radius: 0;
        padding: 9px !important;
        background-color: map-get($sh-colors,"orange-dark") !important;
        border-color: map-get($sh-colors,"orange-dark") !important;
        box-shadow: none;
        color: map-get($sh-colors,"white") !important;
      }
      button:active {
        border-color: map-get($sh-colors,"orange-50") !important;
        opacity: 1 !important;
      }
      .k-button:hover {
        background-color: #b94300 !important;
        opacity: 1 !important;
      }
      button:disabled {
        opacity: 40% !important;
      }
    }
  }
  
  .k-reset:first-of-type > li:first-of-type > button {
    border-radius: 20px 20px 0 0;
  }
  .k-reset:last-of-type > li:last-of-type > button {
    border-radius: 0 0 20px 20px;
  }
  