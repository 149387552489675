@import "../colors";

.row {
  display: flex;
  flex-direction: row;
}

.row-item {
  flex-basis: 0;
  flex-grow: 1;
}

.content {
  display: flex;
  flex-direction: column;
}

.col {
  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  height: calc(100vh - 153px);
  overflow: auto;
  margin-top: 120px;
  padding: 18px 15px 15px 15px;
  background-color: map-get($sh-colors, "black-7");
}

.section-title {
  font-family: "Siemens Sans";
  font-size: 27px;
  font-weight: 700;
  line-height: 40px;
  color: map-get($sh-colors, "black-90");
}

.page-content {
  background-color: map-get($sh-colors, "black-7");
}

.spacer {
  flex-grow: 1;
}
