@import "../colors";


.footer {
    margin-top: 5px;
    padding: 0 12px;
    line-height: 20px;
    hr {
      border-top: 1px solid map-get($sh-colors,"black-20");
    }
    ul {
      list-style: none;
      margin: 10px 0 5px 0;
      padding: 0;
      li {
        .footer-item {
          color: map-get($sh-colors,"black-60");
          text-decoration: none;
          font-size: 14px;
        }
  
        a {
          transition: color 0.21s ease-out 0s;
        }
        a:hover {
          color: map-get($sh-colors,"black-90");
        }
      }
      li::after {
        display: inline-block;
        content: "|";
        color: rgb(102, 102, 102);
        margin: 0px 0.2em;
      }
      li:last-of-type::after {
        display: none;
      }
    }
  }