.k-icon {
  font-family: "SH Icon";
}
.k-i-caret-alt-left::before {
  content: "\e833";
}
.k-i-caret-alt-right::before {
  content: "\e827";
}
.k-i-caret-alt-up::before {
  content: "\e84f";
}
.k-i-caret-alt-down::before {
  content: "\e83f";
}
.k-i-caret-double-alt-left::before {
  content: "\e830";
  transform: rotate(90deg);
}
.k-i-caret-double-alt-right::before {
  content: "\e801";
  transform: rotate(90deg);
}

.k-i-sort-asc-small,
.k-i-sort-desc-small {
  font-family: "WebComponentsIcons";
}

.k-i-calendar::before {
  content: "\e804";
}

.k-i-x-circle::before {
  content: "\e817";
}

.k-i-x::before {
  content: "\e817";
}

.k-i-filter::before {
  content: "\e858";
}

.k-i-filter-clear::before {
  content: "\e86c";
}

.k-i-more-vertical::before {
  content: "\e857";
}

.k-i-x-outline::before {
  content: "\e836";
}

.k-i-check-outline::before {
  content: "\e818";
}

.k-i-exclamation-circle::before {
  content: "\e835";
}
