@import "assets/scss/colors";

.loading {
    position: fixed;
    top: 120px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    background-color: map-get($sh-colors,"black-60");
    opacity: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loading-animation {
    animation: 1s linear 0s infinite normal none running spin;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(236, 102, 2) rgb(230, 230, 230) rgb(230, 230, 230);
    border-image: initial;
    border-radius: 50%;
    height: 60px;
    width: 60px;
  }
  
  @keyframes spin {
    to {transform: rotate(360deg);}
  }