@import "../colors";

.k-checkbox {
  border-color: map-get($sh-colors, "orange");
}

.k-checkbox:checked {
  background-color: map-get($sh-colors, "orange");
  border-color: map-get($sh-colors, "orange");
}
#engineer {
  margin-left: 20px;
  height: 18px;
  width: 18px;
  margin-bottom: 2px;
}
