.k-window {
  border-radius: 0px;
}
.k-dialog-buttongroup {
  border-width: 0;
  padding: 20px;
  gap: 10px;
}

.k-dialog-titlebar {
  padding: 20px;
}

.k-dialog-content {
  padding: 20px;
  .add-object,
  .edit-object {
    gap: 10px;
  }
}

.k-animation-container {
  z-index: 10003 !important;
  &.popup-real {
    z-index: 1000 !important;
  }
}
