.login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 300px;
  }
  .login-wrapper {
    margin-top: 40px;
    background-image: linear-gradient(
      180deg,
      #cf4b00 0%,
      rgba(250, 250, 250, 0) 40%
    );
    border-radius: 61px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    .k-card {
      margin: 0;
      padding: 70px;
      height: 480px;
      width: 570px;
      box-shadow: none;
      border-radius: 56px;
      background-image: linear-gradient(
        180deg,
        #fafafa 0%,
        rgba(250, 250, 250, 0) 60%
      );
    }
    .k-card-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100px;
      padding: auto;
      h1 {
        margin: auto;
        font-size: 40px;
        font-family: "Siemens Sans";
        font-weight: 800;
        text-align: center;
      }
      h2 {
        margin: auto;
        font-size: 25px;
        font-family: "Siemens Sans";
        font-weight: 800;
        text-align: center;
      }
    }
    .k-card-body{
        flex-grow:  0;
        height: 100px;
        padding: auto;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: map-get($sh-colors , "black-60" );
        &>*{
            margin: auto;
        }
    }
    .k-card-actions{
        margin-bottom: 50px;
        .k-button{
            min-width: 300px;
            box-shadow: none;
        }
    }
  }
}
