@import "../colors";

.topbar-logo-section {
  a {
    padding: 10px 30px 10px 10px;

    img {
      width: 125px;
    }
  }
}
.topbar-section {
  background-color: white !important;
  z-index: 2222;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0px;
  width: 100%;
  height: 70px;
  i {
    font-size: 24px;
    color: map-get($sh-colors, "black-60");
  }
}

.appmenu {
  background-color: map-get($sh-colors, "black-7") !important;
  z-index: 2222;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
  position: absolute !important;
  top: 70px !important;
  gap: 0;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  border-radius: 1px;
  padding: 10px 0 !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  font-family: "Siemens Sans";
  color: map-get($sh-colors, "black-60") !important;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 !important;
    display: flex;
    gap: 30px;
    .k-selected {
      font-weight: 800;
      text-decoration: underline;
      text-decoration-thickness: 2px;
    }
  }
  li {
    margin: 0 5px !important;
  }
  a {
    color: map-get($sh-colors, "black-60") !important;
    text-decoration: none;
  }
  a:hover {
    cursor: pointer;
    color: map-get($sh-colors, "orange-dark") !important;
  }
}

.appmenu > .k-appbar-section:first-of-type {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  overflow: scroll;
  height: 100%;
  scrollbar-width: none;
  --mask-width: 20px;
  padding-right: var(--mask-width);
  padding-left: var(--mask-width);
  --mask-image-content: linear-gradient(
    to right,
    transparent,
    black var(--mask-width),
    black calc(100% - var(--mask-width)),
    transparent
  );
  mask-image: var(--mask-image-content);
  mask-size: var(--mask-size-content);
  mask-position: 100% 0, 0 0;
  mask-repeat: no-repeat, no-repeat;
  ul > li > a {
    white-space: nowrap;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.k-menu:not(.k-context-menu) {
  color: map-get($sh-colors, "black-60");
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  font-family: "Siemens Sans";
  .k-i-caret-alt-down::before {
    content: "\e803";
  }
}
.divider {
  height: 20px;
  border-left: 2px solid map-get($sh-colors, "black-20");
  margin-left: 8px;
}

.topbar-app-identifier {
  font-family: "SH Headline";
  font-size: 1.1rem;
  height: 100%;
}

.k-avatar {
  background-color: map-get($sh-colors, "black-10") !important;
  color: map-get($sh-colors, "black-60") !important;
}
.k-appbar {
  line-height: 0;
}

.k-appbar-section {
  color: map-get($sh-colors, "black-60") !important;
}

.topbar-app-message {
  font-family: "SH Headline";
  font-size: 1.5rem;
  height: 100%;
  color: red !important;
  background-color: beige;
}

.k-avatar-md {
  width: 40px !important;
  height: 40px !important;
  flex-basis: 40px !important;
}

.topbar-username {
  line-height: 20px;
}

.topbar-role {
  line-height: 20px;
}

.info {
  padding: 10px 5px;
  i {
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    font-size: 14px;
    &::before {
      margin: 0 1px 0 0;
    }
  }
}

.popup-content {
  font-family: "Siemens Sans";
  padding: 10px 15px;
  color: map-get($sh-colors, "black-90");
  line-height: 20px;
  background-color: map-get($sh-colors, "white");
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 8px, rgb(0 0 0 / 5%) 0px -1px 4px;
}
