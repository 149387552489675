@import "../colors";


.k-floating-label-container.k-focus > .k-label {
  color: map-get($sh-colors, "orange");
}
.k-floating-label-container.k-empty {
  .k-label {
    top: 19px;
    left: 15px;
  }
}
.k-floating-label-container > .k-label,.k-floating-label-container.k-focus > .k-label {
  left: 15px;
  top: 5px;
}
.k-floating-label-container:focus-within > .k-label {
  top: 5px;
  left: 15px;
}

.k-floating-label-container {
  padding-top: 0;
  input {
    height: 100%;
    padding: 25px 15px 3px 15px;
  }

  .k-input {
    height: 57px;
    input {
      height: 100%;
      padding: 25px 15px 3px 15px;
    }
  }
}
