@import "../colors";
.k-button {
  font-family: "Siemens Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  border-radius: 100px;
  padding: 10px 20px;
  border-width: 1px;
  text-transform: none;

  i {
    font-family: "SH Icon";
  }
  .k-button-text {
    text-transform: none;
  }
  transition: all 0.21s ease-out 0s;
}

.k-button-solid-primary {
  background-color: map-get($sh-colors, "orange-dark");
  border-color: map-get($sh-colors, "orange-dark");
}

.k-button:hover::before {
  opacity: 0 !important;
}

.k-button-solid-primary:hover {
  background-color: #b94300;
  border-color: #b94300;
}

.k-button-solid-primary:active {
  background-color: #a73d00;
  border-color: #ec6602;
}

.k-button-solid-primary:disabled {
  background-color: map-get($sh-colors, "orange-dark");
  border-color: map-get($sh-colors, "white");
  color: map-get($sh-colors, "white");
  opacity: 40%;
}

.k-button-outline-base {
  background-color: map-get($sh-colors, "white") !important;
  border-color: map-get($sh-colors, "black-30");
}

.k-button-outline-base:hover {
  background-color: map-get($sh-colors, "black-10") !important;
}

.k-button-outline-base:active {
  background-color: map-get($sh-colors, "black-20") !important;
}
.k-button:focus::before {
    opacity: 0 !important;
  }
  
  .k-button:active::before {
    opacity: 0 !important;
  }
  
  .excel-export{
    background-color: transparent;
    box-shadow: none;
    border-color: transparent;
    transition: background-color 0.1s ease-in-out;
    padding: 2px;
    margin: 2px 20px;
    svg{
      color: green;
    }
    &:active{
      box-shadow: none;
      background-color: map-get($sh-colors, "black-20");
    }
  }
  