@import "../colors";

input {
  height: 50px;
}

.k-picker-solid:focus-within,
.k-input-solid:focus-within {
  border-bottom-color: map-get($sh-colors, "orange") !important;
}

.k-input-solid:focus,
.k-picker-solid:focus,
.k-input-solid.k-focus {
  border-bottom-color: map-get($sh-colors, "orange") !important;
}
.k-input > button {
  border-radius: 0;
}

.k-input > button:hover::before {
  opacity: 0.08 !important;
}

.k-input {
  background-color: map-get($sh-colors, "black-5") !important;
}
