.k-multiselect{
  height: auto;
  min-height: 57px;
  .k-chip-list {
    margin-top: 20px;
    padding: 4px 10px;
    .k-chip {
      background-color: map-get($sh-colors, "white");
      border-radius: 0;
      padding: 2px 1px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      font-family: "Siemens Sans";
      .k-chip-actions{
        color: map-get($sh-colors, "black-60");
        .k-chip-action .k-icon{
          font-size: 12px;
          margin: 2px;
        }
      }
    }
  }
}
.k-dropdownlist {
  height: auto;
  min-height: 57px;
  .k-input-inner{
    padding: 25px 15px 3px 15px;
  }
}

.k-floating-label-container .k-input.k-multiselect {
  height: auto;
  input {
    padding: 3px 15px;
    height: 24px;
  }
}
