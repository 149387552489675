/* SH-Bree-Headline Basic Latin Glyphs and Greek & Cyrillic */
@font-face {
  font-family: "SH Headline";
  src: local("SH-Bree-Headline"), local("SH-Bree-Headline Global"),
    url("SH-Bree-Headline/Europe/SH-Bree-HeadlineEUR.woff2") format("woff2"),
    url("SH-Bree-Headline/Europe/SH-Bree-HeadlineEUR.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+020-21b, U+1e0c-203a, U+374-4f3; /* Latin +  Greek & Cyrillic glyphs */
}
@font-face {
  font-family: "SH Headline";
  /* Gets "faux bold" in Safari since local TTF is only available in regular style */
  src: url("SH-Bree-Headline/Europe/SH-Bree-HeadlineEUR.woff2") format("woff2"),
    url("SH-Bree-Headline/Europe/SH-Bree-HeadlineEUR.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  unicode-range: U+020-21b, U+1e0c-203a, U+374-4f3; /* Latin +  Greek & Cyrillic glyphs */
}

/* partial support for oblique headlines */
@font-face {
  font-family: "SH Headline";
  /* Chrome doesn´t use the italic style of the local font */
  src: url("SH-Bree-Headline/SH-Bree-Headline-Oblique.woff2") format("woff2"),
    url("SH-Bree-Headline/SH-Bree-Headline-Oblique.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  unicode-range: U+020-07e, U+0A0-17f; /* Basic Latin and Latin Extended-A glyphs */
}
@font-face {
  font-family: "SH Headline";
  /* Chrome doesn´t use the italic style of the local font */
  /* Gets "faux bold" in Safari since local TTF is only available in regular style */
  src: url("SH-Bree-Headline/SH-Bree-Headline-Oblique.woff2") format("woff2"),
    url("SH-Bree-Headline/SH-Bree-Headline-Oblique.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  unicode-range: U+020-07e, U+0A0-17f; /* Basic Latin and Latin Extended-A glyphs */
}

/* SH-Bree-Headline Arabic, Hebrew only */
@font-face {
  font-family: "SH Headline";
  src: local("SH-Bree-Headline Global"),
    url("SH-Bree-Headline/MiddleEast/SH-Bree-HeadlineME.woff2") format("woff2"),
    url("SH-Bree-Headline/MiddleEast/SH-Bree-HeadlineME.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+5af-6f9, U+fc5e-fc63, U+fdf2; /* Arabic, Hebrew glyphs */
}
@font-face {
  font-family: "SH Headline";
  /* local TTF only available as regular */
  src: url("SH-Bree-Headline/MiddleEast/SH-Bree-HeadlineME.woff2")
      format("woff2"),
    url("SH-Bree-Headline/MiddleEast/SH-Bree-HeadlineME.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  unicode-range: U+5af-6f9, U+fc5e-fc63, U+fdf2; /* Arabic, Hebrew glyphs */
}

/* SH-Bree-Headline Thai only */
@font-face {
  font-family: "SH Headline";
  src: local("SH-Bree-Headline Global"),
    url("SH-Bree-Headline/Thai/SH-Bree-HeadlineThai.woff2") format("woff2"),
    url("SH-Bree-Headline/Thai/SH-Bree-HeadlineThai.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+e01-e5b; /* Thai glyphs */
}
@font-face {
  font-family: "SH Headline";
  /* local TTF only available as regular */
  src: url("SH-Bree-Headline/Thai/SH-Bree-HeadlineThai.woff2") format("woff2"),
    url("SH-Bree-Headline/Thai/SH-Bree-HeadlineThai.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  unicode-range: U+e01-e5b; /* Thai glyphs */
}

/* Siemens Sans */
@font-face {
  font-family: "Siemens Sans";
  src: local("Siemens Sans"), local("SiemensSans-Roman"),
    url("SiemensSans/SiemensSans-Roman.ttf") format("truetype"),
    url("SiemensSans/SiemensSans-Roman.woff2") format("woff2"),
    url("SiemensSans/SiemensSans-Roman.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Siemens Sans";
  src: local("Siemens Sans Italic"), local("SiemensSans-Italic"),
    url("SiemensSans/SiemensSans-Italic.woff2") format("woff2"),
    url("SiemensSans/SiemensSans-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Siemens Sans";
  src: local("Siemens Sans Bold"), local("SiemensSans-Bold"),
    url("SiemensSans/SiemensSans-Bold.woff2") format("woff2"),
    url("SiemensSans/SiemensSans-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Siemens Sans";
  src: local("Siemens Sans Italic Bold"), local("SiemensSans-BoldItalic"),
    url("SiemensSans/SiemensSans-BoldItalic.woff2") format("woff2"),
    url("SiemensSans/SiemensSans-BoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Siemens Sans";
  src: local("Siemens Sans Black"), local("SiemensSans-Black"),
    url("SiemensSans/SiemensSans-Black.woff2") format("woff2"),
    url("SiemensSans/SiemensSans-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Siemens Sans";
  src: local("Siemens Sans Italic Black"), local("SiemensSans-BlackItalic"),
    url("SiemensSans/SiemensSans-BlackItalic.woff2") format("woff2"),
    url("SiemensSans/SiemensSans-BlackItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

/* SH-Bree-Text subset for Arabic & Hebrew */
@font-face {
  font-family: "SH-Bree-Text";
  src: local("SH-Bree-Text Global Light"),
    url("SH-Bree-Text/MiddleEast/SH-Bree-TextME-Light.woff2") format("woff2"),
    url("SH-Bree-Text/MiddleEast/SH-Bree-TextME-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+5af-6f9, U+fc5e-fc63, U+fdf2; /* Arabic, Hebrew glyphs */
}
@font-face {
  font-family: "SH-Bree-Text";
  src: local("SH-Bree-Text Global Bold"),
    url("SH-Bree-Text/MiddleEast/SH-Bree-TextME-Bold.woff2") format("woff2"),
    url("SH-Bree-Text/MiddleEast/SH-Bree-TextME-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  unicode-range: U+5af-6f9, U+fc5e-fc63, U+fdf2; /* Arabic, Hebrew glyphs */
}

/* SH Bree Text subset for Thai */
@font-face {
  font-family: "SH-Bree-Text";
  src: local("SH-Bree-Text Global Light"),
    url("SH-Bree-Text/Thai/SH-Bree-TextThai-Light.woff2") format("woff2"),
    url("SH-Bree-Text/Thai/SH-Bree-TextThai-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+e01-e5b; /* Thai glyphs */
}
@font-face {
  font-family: "SH-Bree-Text";
  src: local("SH-Bree-Text Global Bold"),
    url("SH-Bree-Text/Thai/SH-Bree-TextThai-Bold.woff2") format("woff2"),
    url("SH-Bree-Text/Thai/SH-Bree-TextThai-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  unicode-range: U+e01-e5b; /* Thai glyphs */
}
