.k-notification {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba($color: #ffffff, $alpha: 0.6);
}
.k-notification-container {
  margin: 7px;
}
.k-notification-error {
  .k-notification-status {
    color: map-get($sh-colors, "red");
    border-radius: 9px;
    border-width: 2px;
    border-style: solid;
    padding: 8px;
  }
  border-left-width: 3px;
  border-color: map-get($sh-colors, "red");
  border-style: solid;
}

.k-notification-success {
  .k-notification-status {
    color: map-get($sh-colors, "green");
    border-radius: 9px;
    border-width: 2px;
    border-style: solid;
    padding: 8px;
  }
  border-left-width: 3px;
  border-color: map-get($sh-colors, "green");
  border-style: solid;
}
.k-notification-warning {
  .k-notification-status {
    color: map-get($sh-colors, "yellow");
    border-radius: 9px;
    border-width: 2px;
    border-style: solid;
    padding: 8px;
  }
  border-left-width: 3px;
  border-color: map-get($sh-colors, "yellow");
  border-style: solid;
}
.k-notification-group {
  z-index: 10004;
}
