.k-upload .k-upload-button {
  background-color: white;
  border-color: map-get($sh-colors, "black-30");
  input:hover{
    cursor: cursor;
  }
}


.k-upload-button {
  span {
    display: none;
  }
}
.k-upload-button:after {
  content: "Ajouter un fichier" !important;
  opacity: 1;
  display: block !important;
  position: relative;
}

.k-dropzone .k-dropzone-hint {
  display: none;
}

.k-dropzone:after {
  content: "Déposer ici les fichiers à télécharger" !important;
  font-family: "Siemens Sans";
  opacity: 1;
  display: block !important;
  position: relative;
}

.k-upload {
  border-width: 0 0 1px 0;
  border-color: #00000061;
  .k-dropzone {
    background-color: map-get($sh-colors, "black-5");
  }
}

.file-item {
  align-items: center;
  .download-button{
    background-color: transparent;
    border-style: hidden;
    flex-grow: 1;
    padding-left: 20px;
    text-align: start;
  }
  .file-name {
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
  .file-size {
    padding: 0 20px;
  }
  .delete-file {
    color: map-get($sh-colors, "red-dark");
    font-size: 24px;
  }
  height: 50px;
  border-width: 0 0 1px 0;
  border-color: #b3b3b3;
  border-style: solid;
  font-size: 16px;
  font-family: "Siemens Sans";
}
