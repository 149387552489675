@import "../colors";

.k-form {
  line-height: 20px !important;
}

.k-form-legend {
  color: map-get($sh-colors, "orange") !important;
  border-width: 0 !important;
  font-weight: 700;
  text-transform: none !important;
  font-size: 16px !important;
  height: 20px;
  margin-left: 15px !important;
  margin-bottom: 10px !important;
}
.k-form-field {
  margin-top: 5px;
}

.k-form-separator {
  margin: 20px 0 0 !important;
}

.k-form-buttons {
  justify-content: end;
}
.required {
  .listbox-field-title::after,
  .k-dropzone-hint::after,
  .k-floating-label-container > .k-label::after {
    content: " *";
    color: red;
    margin-left: 5px;
  }
}

.form-section {
  margin-bottom: 20px;
}

.modality {
  color: map-get($sh-colors, "black-60") !important;
  font-weight: 700;
  font-size: 16px !important;
  margin-left: 30px !important;
}

.param-section {
  margin: 10px 0;
}
