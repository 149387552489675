.scheduler-export-div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(#000, 0.5);

  .export-overlay {
    height: calc(100vh - 150px);
    padding: 15px;
    .k-scheduler {
      margin-top: 10px;
    }
    overflow-y: scroll;
    .export-nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      button {
        height: 36px;
        margin-right: 20px;
      }
    }
  }
}
