.k-datetimepicker>.k-input-button{
  padding: 4px 4px;
  margin: 5px 10px;
  height: 30px;
  width: 30px;
  top: 8px;
  border-radius: 4px;
}


.k-calendar .k-calendar-view .k-today {
  color: map-get($sh-colors, "orange-dark");
}

.k-calendar .k-calendar-td.k-selected .k-link {
  border-color: map-get($sh-colors, "orange-dark");
  color: white;
  background-color: map-get($sh-colors, "orange-dark");
}

.k-calendar .k-calendar-td.k-selected:hover .k-link {
  color: white;
  background-color: #b94300;
}

.k-calendar .k-calendar-td:hover .k-link {
  background-color: map-get($sh-colors, "black-10");
}

.k-calendar .k-calendar-nav-today {
  color: map-get($sh-colors, "orange-dark");
}
.k-calendar .k-calendar-nav-today:hover {
  color: #b94300;
}

.k-time-header .k-time-now {
  color: map-get($sh-colors, "orange-dark");
}

.k-time-header .k-time-now:hover {
  color: #b94300;
}
